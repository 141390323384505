<script setup lang="ts">
  import { JobGroup } from '@borg/types';
  import { MpMasonryWall, MpText } from '@borg/ui';

  defineProps<{
    items: JobGroup[];
  }>();

  const ssrHydration = computed(() => process.server || useNuxtApp().isHydrating);
</script>

<template>
  <div v-if="items.length">
    <MpText
      as="h6"
      style="margin-bottom: var(--mp-space-50)"
    >
      {{ $t('SEARCH_PAGE.FEATURED_JOBS_SECTION_TITLE') }}
    </MpText>
    <MpMasonryWall
      :items="items"
      name="search"
      :width="300"
      :gap="16"
      :columns="4"
      :ssr-hydration="ssrHydration"
    >
      <template #default="{ item }">
        <JobCardFeatured
          :job-group="item"
          source="search_results"
        />
      </template>
    </MpMasonryWall>
  </div>
</template>
