<script setup lang="ts">
  import { MpCard } from '@borg/ui';
  import { JobDetailsSource, JobGroup, JobSimple } from '@borg/types';

  const props = defineProps<{
    item: JobGroup | JobSimple;
    source?: JobDetailsSource;
  }>();

  const jobCard = useJobCard.provide();
  jobCard.set(props.item, props.source);
  const organizationCard = useOrganizationCard.provide();
  organizationCard.set(props.item.organization);
</script>

<template>
  <MpCard
    class="job-card"
    border="light-only"
  >
    <OrganizationLogo />
    <div class="job-card__content">
      <JobCardTags />
      <JobCardTitle />
      <JobCardSubtitle />
      <JobCardLocation />
      <JobCardDate />
      <JobCardDescription />
      <slot />
    </div>
  </MpCard>
</template>

<style scoped lang="scss">
  .job-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--mp-space-60);
    background-color: var(--mp-color-surface-40);
    border-color: var(--mp-color-system-a-dark);

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--mp-space-30);
    }
  }
</style>
